.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.protector-address {
  font-size: 14px;
  font-weight: 500;
}

/* ========== Custom Styles Purpose Modal =========== */

.purpose-modal .purpose-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.purpose-modal .modal-body .validator-form {
  padding: 20px 0px 60px;
}

.purpose-modal .modal-body .text-field .MuiInputBase-input,
.purpose-modal .modal-body .text-field .MuiFormLabel-root {
  color: #fff !important;
}

.purpose-modal .modal-body .text-field .MuiInput-underline:before {
  border-bottom: 1px solid #ffffff;
}
.purpose-modal .modal-body .text-field .MuiInput-underline:after {
  border-bottom: 1px solid #ffffff;
}

.purpose-modal .modal-body .validator-form .burn-btn-div {
  display: flex;
  justify-content: center;
}

.purpose-modal .modal-body .validator-form .burn-btn {
  width: 35%;
  color: #ffffff;
  border-radius: 10px;
  background-color: #4c4c4c;
}

/* ========== Custom Styles Purpose Modal =========== */

.pause-modal .modal-content .modal-header .modal-title {
  color: #212529;
  font-size: 18px;
  font-weight: 500;
}

.pause-modal .modal-content {
  padding: 15px 20px;
}