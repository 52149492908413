.page-loader {
  position: absolute;
  z-index: 9999;
  min-height: 105vh;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.9);
}

.page-loader .loader-area {
  min-height: 105vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.page-loader .loader-area .css-1mxr1bq,
.page-loader .loader-area .css-8vx899,
.page-loader .loader-area .css-anak8p {
  width: 80px;
  height: 80px;
}

.page-loader .loader-area  .loading-text {
  color: #888;
  font-size: 22px;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
}
