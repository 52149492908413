.content .edit-add .fee-controller {
    display: flex;
    justify-content: space-between !important;
    padding: 10px 40px;
}

.content .edit-add .fee-controller button {
    min-width: 10% !important;
}

.content .edit-add .fee-controller p {
    font-size: 18px;
}

.content .edit-add .fee-controller .view-data-title {
    font-weight: 600;
}

.content .edit-add .fee-controller a {
    font-weight: 400;
    color: #4c4c4c;
}

.content .edit-add .fee-controller a:hover {
    text-decoration: underline;
}

/* ========== Custom Styles Fee Rate Modal =========== */

.fee-rate-modal .fee-rate-modal-title p {
    color: #fff !important;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  
  .fee-rate-modal .modal-body .validator-form {
    padding: 20px 0px 60px;
  }
  
  .fee-rate-modal .modal-body .text-field .MuiInputBase-input,
  .fee-rate-modal .modal-body .text-field .MuiFormLabel-root {
    color: #fff !important;
  }
  
  .fee-rate-modal .modal-body .text-field .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff;
  }
  .fee-rate-modal .modal-body .text-field .MuiInput-underline:after {
    border-bottom: 1px solid #ffffff;
  }
  
  .fee-rate-modal .modal-body .validator-form .burn-btn-div {
    display: flex;
    justify-content: center;
  }
  
  .fee-rate-modal .modal-body .validator-form .burn-btn {
    width: 35%;
    color: #ffffff;
    border-radius: 10px;
    background-color: #4c4c4c;
  }

  /*** RESPONSIVE ***/

  @media (max-width: 1420px) {
    .content .edit-add .fee-controller p {
      font-size: 14px;
    }
    .content .edit-add .fee-controller button {
      font-size: 12px;
      padding: 7px;
    }
  }

  @media (max-width: 1230px) {
    .content .edit-add .fee-controller p {
      font-size: 12px;
    }
    .content .edit-add .fee-controller button {
      font-size: 10px;
      padding: 5px;
    }
  }

  @media (max-width: 1100px) {
    .content .edit-add .fee-controller {
      padding: 10px 5px;
  }
  }