.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.supplier .supplier-address {
  font-size: 14px;
  font-weight: 500;
}

/* ========== Custom Styles History Slider =========== */

.slider .slider-heading p {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}


/* ========== Custom Styles Minting Modal =========== */

.minting-modal .minting-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.minting-modal .modal-body .validator-form {
  padding: 20px 0px 60px;
}

.minting-modal .modal-body .text-field .MuiInputBase-input,
.minting-modal .modal-body .text-field .MuiFormLabel-root {
  color: #fff !important;
}

.minting-modal .modal-body .text-field .MuiInput-underline:before {
  border-bottom: 1px solid #ffffff;
}
.minting-modal .modal-body .text-field .MuiInput-underline:after {
  border-bottom: 1px solid #ffffff;
}

.minting-modal .modal-body .validator-form .minting-btn-div {
  display: flex;
  justify-content: center;
}

.minting-modal .modal-body .validator-form .minting-btn {
  width: 35%;
  color: #ffffff;
  border-radius: 10px;
  background-color: #4c4c4c;
}

/* ========== Custom Styles Burn Modal =========== */

.burn-modal .burn-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.burn-modal .modal-body .validator-form {
  padding: 20px 0px 60px;
}

.burn-modal .modal-body .text-field .MuiInputBase-input,
.burn-modal .modal-body .text-field .MuiFormLabel-root {
  color: #fff !important;
}

.burn-modal .modal-body .text-field .MuiInput-underline:before {
  border-bottom: 1px solid #ffffff;
}
.burn-modal .modal-body .text-field .MuiInput-underline:after {
  border-bottom: 1px solid #ffffff;
}

.burn-modal .modal-body .validator-form .burn-btn-div {
  display: flex;
  justify-content: center;
}

.burn-modal .modal-body .validator-form .burn-btn {
  width: 35%;
  color: #ffffff;
  border-radius: 10px;
  background-color: #4c4c4c;
}